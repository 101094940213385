// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-contact-confirm-jsx": () => import("./../../../src/pages/contact-confirm.jsx" /* webpackChunkName: "component---src-pages-contact-confirm-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-crypto-jsx": () => import("./../../../src/pages/crypto.jsx" /* webpackChunkName: "component---src-pages-crypto-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-news-bitcoin-jsx": () => import("./../../../src/pages/news/bitcoin.jsx" /* webpackChunkName: "component---src-pages-news-bitcoin-jsx" */),
  "component---src-pages-news-irish-jsx": () => import("./../../../src/pages/news/irish.jsx" /* webpackChunkName: "component---src-pages-news-irish-jsx" */),
  "component---src-pages-news-uk-jsx": () => import("./../../../src/pages/news/uk.jsx" /* webpackChunkName: "component---src-pages-news-uk-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-subscribe-confirm-jsx": () => import("./../../../src/pages/subscribe-confirm.jsx" /* webpackChunkName: "component---src-pages-subscribe-confirm-jsx" */),
  "component---src-pages-subscribe-thanks-jsx": () => import("./../../../src/pages/subscribe-thanks.jsx" /* webpackChunkName: "component---src-pages-subscribe-thanks-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

